<template>
  <div class="group-18785">
    <v-btn
        rounded
        fab
          class="btn-plus"
          @click="mpplus()"
          text
        >
        <v-img
        class="onHover"
        :src="require('@/assets/buy-ticket/btn-plus.svg')">
        </v-img>
    </v-btn>  
      <v-btn
        fab
        rounded
          class="btn-minus"
          @click="mpminus()"
        >
        <v-img
        class="onHover"
        :src="require('@/assets/buy-ticket/btn-minus.svg')">
        </v-img>
        </v-btn>

      <input class="input-label" v-model="newValue" disabled />

  </div>
</template>

<script>
export default {
  name: "MinPlusButtonCart",
  props: {
    value: {
      default: 0,
      type: Number
    },
    // newValue: {
    //   default: 0,
    //   type: Number
    // },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    }
  },
  data () {
    return {
      newValue: 0
    }
  },
  methods: {
    getNotificationClass (notification) {
      return `alert alert-${notification.type}`
    },
    mpplus: function () {
      if (this.max === undefined || (this.newValue < this.max)) {
        this.newValue = this.newValue + 1
        this.$emit('input', this.newValue)
      }
    },
    mpminus: function () {
      if ((this.newValue) > this.min) {
        this.newValue = this.newValue - 1
        this.$emit('input', this.newValue)
      }
    }
  },
  watch: {
    value: {
      handler: function (newVal) {
        this.newValue = newVal
      }
    }
  },
  created: function () {
    this.newValue = this.value
  }
}
</script>
<style lang="scss" scoped>
  // .minusplusnumber {
  //     border:1px solid silver;
  //     border-radius:5px;
  //     background-color: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);;
  //     margin:0 5px 0 5px;
  //     display:inline-block;
  //     user-select: none;
  //     text-align:center;

  // }
  // .minusplusnumber div {
  //     display:inline-block;
  // }
  // .minusplusnumber #field_container input {
  //   //   border:1px solid silver;
  //   //   border-radius:5px;
  //     width:50px;
  //     text-align:center;
  //     padding:3px;
  // }
  // .minusplusnumber .mpbtn {
  //     padding:3px 10px 3px 10px;
  //     cursor:pointer;
  //     border-radius:5px;
  // }
  // .minusplusnumber .mpbtn:hover {
  //     background-color:#DDD;
  // }
  // .minusplusnumber .mpbtn:active {
  //     background-color:#c5c5c5;
  // }


/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .group-18785 {
    border-radius: 23px;
    height: 29px;
    width: 80px;
    padding: 2px 6px 2px 4px;
    position: relative;
    border: 1px solid rgba(75, 177, 78, 0.35);
  }

  .btn-plus{
    top: -5px;
    height: 30px;
    width: 30px;
    left: 45px;
  }
  .btn-minus{
    top: -5px;
    height: 30px;
    width: 30px;
    left: -40px;
  }

  .input-label{
    top: -27px;
    width: 64px;
    color: rgba(75, 177, 78, 1);
    text-align: center;
    position: relative;
  }

  .onHover{
    cursor: pointer;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .group-18785 {
    border-radius: 23px;
    height: 29px;
    width: 80px;
    padding: 2px 6px 2px 4px;
    position: relative;
    border: 1px solid rgba(75, 177, 78, 0.35);
  }

  .btn-plus{
    top: -5px;
    height: 30px;
    width: 30px;
    left: 45px;
  }
  .btn-minus{
    top: -5px;
    height: 30px;
    width: 30px;
    left: -40px;
  }

  .input-label{
    top: -27px;
    width: 64px;
    color: rgba(75, 177, 78, 1);
    text-align: center;
    position: relative;
  }

  .onHover{
    cursor: pointer;
  }
}
</style>